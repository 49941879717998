/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.menu-name {
  display: block;
  margin-top: -21px;
}
::v-deep .ant-table-row-level-0 .menu-name {
  margin-left: 25px;
}
::v-deep .ant-table-row-level-1 .menu-name {
  margin-left: 45px;
}
::v-deep .ant-table-row-level-2 .menu-name {
  margin-left: 65px;
}
::v-deep .ant-table-row-level-3 .menu-name {
  margin-left: 85px;
}
.tabs {
  width: 100%;
  height: 38px;
  background: #eeeeee;
  border-radius: 4px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  margin-bottom: 20px;
  display: flex;
}
.tabs .btn {
  width: 100px;
  height: 100%;
  line-height: 38px;
  text-align: center;
  font-size: 14px;
  color: #333333;
  cursor: pointer;
}
.tabs .active {
  background: #ffffff;
}
.row-action-btn {
  margin: 0 5px;
}
